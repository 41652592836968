import React from 'react';

const ContactForm = () =>
  <section>
    <form method="post" action="https://getform.io/f/84789703-09d0-4bb0-88e1-d2d247c2f19e">
      <div className="fields">
        <div className="field half">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="5" />
        </div>
      </div>
      <ul className="actions">
        <li>
          {/* <a href="/#" className="button submit" type="submit">
            Send Message
          </a> */}
          <button type="submit">Send Message</button>
        </li>
      </ul>
    </form>
  </section>

export default ContactForm;

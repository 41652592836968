import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'SIEM Engineering',
      description: 'Experience integrating and decection engineering for hundreds of data sources with many different SIEM platforms including Azure Sentinel and Google Cloud\'s Chronicle.',
      iconClass: 'fa-code',
    },
    {
      heading: 'MITRE ATT&CK',
      description: 'Profile your security posture based on threat model, visibility, and detection capabilities.',
      iconClass: 'fa-desktop',
    },
    {
      heading: 'Security Workflow Automation',
      description: 'Experience with SOAR platforms and reducing the burden on your IT operations.',
      iconClass: 'fa-lock',
    },
    {
      heading: 'Jack Of All Trades',
      description: 'Years of security product expertise and best practice knowledge across EDR, network, cloud, email and identity.',
      iconClass: 'fa-cog',
    },

  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
